import styled from "styled-components"

export const Wrapper = styled.nav`
  display: flex;
  justify-content: space-between;
  flex-direction: ${props => (props.isFirst ? "row-reverse" : "row")};
  font-family: "Teko", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 800;

  .navBack {
    color: #fff;
    position: relative;
    width: 120px;
    height: 35px;
    display: block;
    background: linear-gradient(90deg, #0a0a0a, #1c1c1c);

    margin-bottom: 30px;
    text-align: center;
    padding: 3px 0 0 0;
    border-radius: 3px;
    :hover {
      background: linear-gradient(90deg, #1c1c1c, #0a0a0a);
    }
  }

  .navForward {
    color: #fff;
    position: relative;
    width: 120px;
    height: 35px;
    display: block;
    background: linear-gradient(90deg, #0a0a0a, #1c1c1c);
    padding: 3px 0 0 0;
    margin-bottom: 30px;
    text-align: center;
    border-radius: 3px;
    :hover {
      background: linear-gradient(90deg, #1c1c1c, #0a0a0a);
    }
  }
`
export const H2 = styled.h2`
  margin-top: 45px;
`
