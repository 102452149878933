import styled from "styled-components"

export const Wrapper = styled.div`
  font-family: "Teko", Arial, Helvetica, sans-serif;
  font-weight: 800;
  font-size: 1rem;
  margin: 10px 0;
  padding-left: 0;
  margin-left: 0;
  background: linear-gradient(90deg, #1c1c1c, #0a0a0a);
  width: 100%;
  border-radius: 2px;
  :hover {
    background: linear-gradient(90deg, #0a0a0a, #1c1c1c);
  }
  a {
    color: #fff;
    margin: 10px;
  }

  a:hover {
    color: #60c1aa;
  }

  .divider {
    margin: 0 10px;
  }
`
