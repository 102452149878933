import styled from "styled-components"

export const Wrapper = styled.aside`
  margin: 40px 0 0 0;
  min-width: 25%;

  @media (min-width: 992px) {
    margin: 125px 15px 0 0;
  }
`

export const Menu = styled.ul`
  list-style-type: none;
  padding: 0 20px 0 0px;
  margin: 0;

  .sidebar-menu-header {
    font-family: "Chub Gotic", sans-serif;
    border-bottom: 2px #e4e4e4 solid;
    font-weight: 600;
    letter-spacing: 6px;
    margin: 0 0 20px 0;
    padding: 0 0 5px 0;
    text-transform: uppercase;
    color: #fff;
  }

  li {
    margin: 0 0 5px 0px;
    color: #000;
    font-family: "Chub Gotic", sans-serif;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 1px;

    a {
      color: #fff !important;
    }

    a:hover {
      color: #60c1aa !important;
    }
  }

  a {
    .item {
      background: linear-gradient(90deg, #0a0a0a, #1c1c1c);
      border-radius: 3px;
      padding: 5px 0;
      margin: 10px 0;
      color: #fff;
      :hover {
        background: linear-gradient(90deg, #1c1c1c, #0a0a0a);
        color: #60c1aa;
      }

      span {
        padding: 0 10px;
      }

      .count {
        margin: 0 10px 0 0;
        padding: 0;
        font-size: 0.8rem;
        color: black;
        display: block;
        text-align: center;
        float: right;
        font-weight: 600;
        color: #fff;
      }
    }
  }

  .sidebar-highlighted {
    color: #60c1aa;
  }

  img {
    float: right;
    padding: 0 10px 0 0;
    width: 38px;
    height: auto;
    filter: invert(100%);
  }
`
